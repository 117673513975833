// import './layers.css';
import { useEffect } from 'react';

export default function CardLayers({opacity}){

    return (
        <>
            <div className="card__layer0"></div>
            <div className="card__layer1"></div>
            <div className="card__layer2"></div>
            <div className="card__layer3"></div>
        </>
    );
}