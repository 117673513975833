
import { useEffect, useState } from 'react';
import { Motion, spring, presets } from 'react-motion';
import { useParams } from "react-router-dom";
import Card3D from './Card3d';

const originUrl = window.location.origin;


const params = window.location.search;
let m = false;
let small = false;
if (params) {
  //console.log(params)
  const test = new URLSearchParams(params);
  const color = test.get("bgColor");
  m = test.get("m");
  small = test.get("small");
}


export default function Card(props) {

  useEffect(() => {


    window.addEventListener("message", (ev) => {
      // console.log(ev.data.type);
      // console.log("fee");
      if (!ev.data.type) return;

      if (ev.data.type !== "mousemove") return;
      const target = document.getElementById("card-container");
      const event = {
        target: target,
        clientX: ev.data.x,
        clientY: ev.data.y
      }

      setO(1);
      setSpringConfig({ stiffness: 150, damping: 10 });

      handleMouseMove(event);
    }
    );
  }, []);

  var { collectionid, edition, rarity, id } = useParams();
  if (!collectionid && !rarity && !id) {
    collectionid = props.collectionid;
    edition = props.edition;
    rarity = props.rarity;
    id = props.id;
  }

  const [rX, setrX] = useState(0);
  const [rY, setrY] = useState(0);
  const [springConfig, setSpringConfig] = useState({ stiffness: 10, damping: 15 });
  const [posX, setPosX] = useState(50);
  const [posY, setPosY] = useState(50);
  const [hyp, setHyp] = useState(0);
  const [o, setO] = useState(0);
  const [mX, setmX] = useState(0.5);
  const [mY, setmY] = useState(0.5);

  let timer = null;

  let originUrlDatas = 'https://cardsdata.dolz.io';
  if (window.location.hostname === 'localhost' || window.location.hostname === 'devcards.dolz.io') {
    originUrlDatas = "https://devcardsdata.dolz.io";
  }

  const currentOrigin = window.location.hostname;

  // const mask = `${originUrlDatas}/iStripper/${collectionid}/${rarity}/reflectionMaskx.png`
  //const bg2 = `https://cards.dolz.io/2.png`;
  const reverse = `https://cards.dolz.io/sparkles-reverse.gif`;
  const sparklesEpic = `https://cards.dolz.io/sparklesEpic.gif`;

  const reverseSmall = `https://cards.dolz.io/sparkles-reverse_smx.gif`;
  const sparklesEpicSmall = `https://cards.dolz.io/sparklesEpic_smx.gif`;

  const legendaryPattern = `https://cards.dolz.io/full.png`;
  const limitedMask = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Limited/reflectionMaskx.png`;
  const rareMask = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Rare/reflectionMaskx.png`;
  const epicMask = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Epic/reflectionMaskx.png`;
  const legendaryMask = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Legendary/reflectionMaskx.png`;

  const limitedMaskSmall = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Limited/reflectionMaskx_smx.png`;
  const rareMaskSmall = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Rare/reflectionMaskx_smx.png`;
  const epicMaskSmall = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Epic/reflectionMaskx_smx.png`;
  const legendaryMaskSmall = `${originUrlDatas}/iStripper/${collectionid}/${edition}/Legendary/reflectionMaskx_smx.png`;

  // const epicPattern = `https://cards.dolz.io/illusion.png`;

  function setMouseValues(x, y) {
    setmX(x);
    setmY(y);
  }

  function setRotationValues(x, y) {
    setrX(x);
    setrY(y);
  }

  function setPositionValues(x, y) {
    setPosX(x);
    setPosY(y);
  }

  function setHypValues(x) {
    setHyp(x);
  }


  function handleMouseIn() {
    //console.log('in')
    clearTimeout(timer);
    setO(1);
    setSpringConfig({ stiffness: 150, damping: 10 });
  }

  function handleMouseMove(event) {
    if (m == 1) return;
    //clearTimeout(timer)
    //setO(1);
    //setSpringConfig({ stiffness: 150, damping: 10 });
    const el = event.target;
    let w = el.clientWidth;
    let h = el.clientHeight;
    let b = el.getBoundingClientRect();

    let X = (event.clientX - b.left) / w;
    let Y = (event.clientY - b.top) / h;

    setMouseValues(X, Y);

    let rX = -(X - 0.5) * 20;
    let rY = (Y - 0.5) * 30;

    setRotationValues(rX, rY);

    setPositionValues((50 - rX) * .8, (50 + rY) * .8);
    const hyp = Math.min(Math.max(Math.sqrt(Math.pow(event.clientX - b.left - (el.offsetLeft + (w / 2)), 2) + Math.pow(event.clientY - b.top - (el.offsetTop + (h / 2)), 2)) / 200 - 0.1, 0), 1);
    //setHypValues(Math.floor(hyp * 100) / 100);
    setHypValues(hyp);
  }

  function handleMouseLeave() {
    // console.log('out')

    timer = setTimeout(() => {
      setSpringConfig({ stiffness: 80, damping: 7 });
      setO(0);
      setRotationValues(0, 0);
      setPositionValues(50, 50);
      setMouseValues(0.5, 0.5);
      setHyp(0);
    }, 500);
  }

  return (
    <div id="card-container" className={`${rarity}`} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseIn}>

      {rarity === 'Legendary' && (
        <>
          {document.documentElement.style.setProperty(`--legendaryPattern`, `url("${legendaryPattern}")`)}

          {small == 1 ? (
            <>
              {document.documentElement.style.setProperty(`--Legendary-maskUrl`, `url("${legendaryMaskSmall}")`)}
              {document.documentElement.style.setProperty(`--sparkles`, `url("${reverseSmall}")`)}
            </>
          ) : (
            <>
              {document.documentElement.style.setProperty(`--Legendary-maskUrl`, `url("${legendaryMask}")`)}
              {document.documentElement.style.setProperty(`--sparkles`, `url("${reverse}")`)}
            </>
          )}


        </>
      )}


      {rarity === 'Epic' && (
        <>
          {document.documentElement.style.setProperty(`--legendaryPattern`, `url("${legendaryPattern}")`)}

          {small == 1 ? (
            <>
              {document.documentElement.style.setProperty(`--Epic-maskUrl`, `url("${epicMaskSmall}")`)}
              {document.documentElement.style.setProperty(`--sparklesEpic`, `url("${sparklesEpicSmall}")`)}
            </>
          ) : (
            <>
              {document.documentElement.style.setProperty(`--Epic-maskUrl`, `url("${epicMask}")`)}
              {document.documentElement.style.setProperty(`--sparklesEpic`, `url("${sparklesEpic}")`)}
            </>
          )}


        </>
      )}

      {rarity === 'Rare' && (
        <>

          {small == 1 ? (
            <>
              {document.documentElement.style.setProperty(`--Rare-maskUrl`, `url("${rareMaskSmall}")`)}
              {document.documentElement.style.setProperty(`--sparkles`, `url("${reverseSmall}")`)}
            </>
          ) : (
            <>
              {document.documentElement.style.setProperty(`--Rare-maskUrl`, `url("${rareMask}")`)}
              {document.documentElement.style.setProperty(`--sparkles`, `url("${reverse}")`)}
            </>
          )}


        </>
      )
      }

      {rarity === 'Limited' && (
        <>

          {small == 1 ? (
            <>
              {document.documentElement.style.setProperty(`--Limited-maskUrl`, `url("${limitedMaskSmall}")`)}
              {document.documentElement.style.setProperty(`--sparkles`, `url("${reverseSmall}")`)}
            </>
          ) : (
            <>
              {document.documentElement.style.setProperty(`--Limited-maskUrl`, `url("${limitedMask}")`)}
              {document.documentElement.style.setProperty(`--sparkles`, `url("${reverse}")`)}
            </>
          )}
        </>
      )
      }



      {/* {document.documentElement.style.setProperty(`--grain`, `url("${grain}")`)} */}
      {/* {document.documentElement.style.setProperty(`--sparklesOriginal`, `url("${sparkles}")`)} */}


      {/* {document.documentElement.style.setProperty(`--metal`, `url("${metal}")`)} */}
      {/* {document.documentElement.style.setProperty(`--glitter`, `url("${glitter}")`)} */}

      {/* {document.documentElement.style.setProperty(`--bg2`, `url("${bg2}")`)} */}
      {/* {document.documentElement.style.setProperty(`--epicPattern`, `url("${epicPattern}")`)} */}

      <div className="card-wrapper">
        <Motion defaultStyle={{ rX: 0, rY: 0, posX: 50, posY: 50, o: 0, x: 0.5, y: 0.5, hyp: 0 }} style={
          {
            rX: spring(rY, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            rY: spring(rX, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            posX: spring(posX, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            posY: spring(posY, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            o: spring(o, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            x: spring(mX, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            y: spring(mY, { stiffness: springConfig.stiffness, damping: springConfig.damping }),
            hyp: spring(hyp, { stiffness: springConfig.stiffness, damping: springConfig.damping })
          }
        }>
          {interpolatedStyles => (
            <Card3D style={interpolatedStyles} card={{ collectionid, edition, rarity, id }} hyp={hyp} />
          )}
        </Motion>
      </div>
    </div>
  )
}