import './App.css';
import Card from './Card';
// import Showcase from './Showcase';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { useEffect, lazy } from 'react';

const Showcase = lazy(() => import('./Showcase.js'));


const params = window.location.search;
var color = "201b38";

if (params) {
  const reg = /^([0-9A-F]{3}){1,2}$/i;
  const test = new URLSearchParams(params);
  const value = test.get("bgColor");

  //console.log(value);
  if (value == "transparent") {
    color = null;
  }
  else if (reg.test(value)) {
    color = value;
  }
}

function App() {

  return (
    <div className="App" style={{ backgroundColor: `#${color ? color : "transparent"}` }}>
      <Router>
        <Routes>
          <Route path="/:collectionid/:edition/:rarity/:id" element={<Card />}></Route>
          <Route path="/showcase" element={<Showcase />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
