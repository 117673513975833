import { useEffect, useMemo } from "react"
import CardLayers from "./CardLayers";

const params = window.location.search;
let m = false;
let small = false;
if (params) {
    //console.log(params)
    const test = new URLSearchParams(params);
    const color = test.get("bgColor");
    m = test.get("m");
    small = test.get("small");
}

export default function Card3D({ style, card, hyp }) {
    let originUrl = 'https://cardsdata.dolz.io';
    if (window.location.hostname === 'localhost' || window.location.hostname === 'devcards.dolz.io') {
        originUrl = "https://devcardsdata.dolz.io";
    }

    let originUrlDatas = 'https://cardsdata.dolz.io';
    if (window.location.hostname === 'localhost' || window.location.hostname === 'devcards.dolz.io') {
        originUrlDatas = "https://devcardsdata.dolz.io";
    }



    return (
        <div id="card-3d" style={{ "transform": `rotateY(${style.rY}deg) rotateX(${style.rX}deg)`, "WebkitTransformStyle": "preserve-3d" }}>
            {document.documentElement.style.setProperty(`--${card.rarity}-x`, 100 * style.x + "%")}
            {document.documentElement.style.setProperty(`--${card.rarity}-y`, 100 * style.y + "%")}
            {document.documentElement.style.setProperty(`--${card.rarity}-posx`, `${style.posX}%`)}
            {document.documentElement.style.setProperty(`--${card.rarity}-posy`, `${style.posY}%`)}
            {document.documentElement.style.setProperty(`--${card.rarity}-bright`, style.hyp)}
            {document.documentElement.style.setProperty(`--${card.rarity}-o`, style.o)}
            {document.documentElement.style.setProperty(`--${card.rarity}-rX`, `${style.rX}px`)}
            {document.documentElement.style.setProperty(`--${card.rarity}-rY`, `${style.rY}px`)}
            <div className="card-animated-bg"></div>
            <div id="card-image">

                {small == 1 ?
                    (
                        <>
                            <img id="card" src={`${originUrlDatas}/iStripper/${card.collectionid}/${card.edition}/${card.rarity}/${card.id}_smx.png`} />
                        </>
                    )
                    :
                    (
                        <>
                            <img id="card" src={`${originUrlDatas}/iStripper/${card.collectionid}/${card.edition}/${card.rarity}/${card.id}_lgx.png`} />
                        </>
                    )
                }

            </div>
            {<CardLayers />}

        </div>
    )
} 